@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap";
:root {
  --color-text: #000;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5f5f5;
  font-family: Roboto, sans-serif;
}

.container {
  max-width: 1024px;
  text-align: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
}

ul {
  list-style: none;
}

.contact {
  margin-top: 1rem;
}

.contact ul {
  justify-content: center;
  margin-top: 1rem;
  display: flex;
}

.contact ul li {
  margin: 0 1rem;
}

.contact ul li a {
  color: var(--color-text);
}

.contact ul li a:hover {
  text-decoration: none;
}

h1, h3 {
  margin-bottom: 1rem;
}

/*# sourceMappingURL=index.0951c6e2.css.map */
