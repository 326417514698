@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap");

@import "./scss/variables";

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

ul {
  list-style: none;
}

/* COMPONENTS */
@import "./components/contact/scss/contact";
@import "./components/headline/scss/headline";
